import {createApi,fetchBaseQuery} from '@reduxjs/toolkit/query/react'

export const historiquedeviseApi=createApi({
    reducerPath:'historiquedeviseApi',
    baseQuery:fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASE_URL,
        credentials: 'include',
    }),
    tagTypes:["Historiquedevises"],
    keepUnusedDataFor:60,
    endpoints:(builder)=>({

        getHistoriquedevise:builder.query({
            query:(id)=>({url:`historiquedevise/${id}`,
            }),
            providesTags:['Historiquedevises']
        }),      
    })
})
export const {useGetHistoriquedeviseQuery}=historiquedeviseApi;
