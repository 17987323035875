import React, { useState } from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Container,
  Stack,
  Typography,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useGetFaqsQuery } from "../../app/services/faqsApi";
import { useCreateFaqMutation } from "../../app/services/faqsApi";

const FaqsList = () => {
  const [question, setQuestion] = useState('');
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const { data: faqs, error, isLoading } = useGetFaqsQuery();
  const [createFaq, { isLoading: isLoading1, isError: isError1 }] = useCreateFaqMutation();

  const handleQuestionChange = (event) => {
    setQuestion(event.target.value);
  };

  const handleSubmit = async () => {
    try {
      const response = await createFaq({ question: question });
      if (response.data) {
        setConfirmationMessage('Votre question a été enregistrée avec succès. Notre réponse sera affichée dans les meilleurs délais');
      }
      setQuestion('');
    } catch (error) {
      console.error('Une erreur s\'est produite lors de la création de la FAQ :', error);
    }
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  const faqsWithNonNullResponse = faqs.filter(faq => faq.reponse !== null);

  return (
    <Box sx={{ backgroundColor: "white", py: 4, mb:4 }}>
      <Container>
        <Box
          sx={{
            backgroundColor: "black",
            color: "yellow",
            pt: 1,
            pb: 1,
            pl: 1,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
          }}
        >
          <Typography variant="h5">FAQs</Typography>
        </Box>
        <Typography paragraph sx={{ padding: 2 }}>
          Explorez nos FAQs pour trouver des réponses à une variété de sujets, y compris nos services, nos politiques, nos procédures et bien plus encore. Si vous ne trouvez pas la réponse que vous cherchez, n'hésitez pas à nous contacter directement. Notre équipe est là pour vous aider et répondre à toutes vos questions.
        </Typography>

        {faqsWithNonNullResponse.map((faq) => (
          <Accordion key={faq.id}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1d-content"
              id="panel1-header"
            >
              <Typography>{faq.question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{faq.reponse}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}

        <Stack sx={{ mt: 2 }}>
          <Typography variant="h6">
            Avez-vous d'autres interrogations ? Nous sommes là pour vous aider !
          </Typography>

          <Stack
            direction="column"
            alignItems="center"
            sx={{
              backgroundColor: "white",
              p: 2,
              borderRadius: 8,
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Ajout d'une légère ombre
            }}
          >
            <TextField
              sx={{ width: "100%", mb: 2 }}
              id="standard-multiline-flexible"
              label="Posez votre question ici"
              value={question}
              onChange={handleQuestionChange}
              multiline
              maxRows={4}
              variant="filled"
            />

            <Button
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              disabled={isLoading1}
            >
              {isLoading1 ? 'Envoi en cours...' : 'Envoyer'}
            </Button>

            {confirmationMessage && (
              <Typography sx={{ color: "green", mt: 1 }}>
                {confirmationMessage}
              </Typography>
            )}
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};

export default FaqsList;
