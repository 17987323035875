import * as React from 'react';
import { useNavigate } from 'react-router-dom'; // Assurez-vous que react-router-dom est installé

import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';

import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const actions = [
  { icon: <LocalGroceryStoreIcon sx={{ color: '#2196F3', fontSize: 32 }}/>, name: 'Réservez', action: 'reservation' },
  { icon: <CardGiftcardIcon sx={{ color: '#FF9800', fontSize: 32}}/>, name: 'Services', action: 'service' },
  { icon: <WhatsAppIcon sx={{ color: '#25D366', fontSize: 32 }}/>, name: 'WhatsApp', action: 'whatsapp' },
];

export default function ControlledOpenSpeedDial() {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const handleActionClick = (action) => {
    if (action === 'reservation') {
      navigate('/reservation');
    } else if (action === 'service') {
      navigate('/services');
    } else if (action === 'whatsapp') {
      window.open('https://wa.me/212703131314', '_blank');
    }
    setOpen(false); // Fermer le SpeedDial après l'action
  };

  return (
    <SpeedDial
      ariaLabel="SpeedDial controlled open example"
      sx={{
        position: 'fixed', // Fixé à l'écran
        bottom: 16, // Distance par rapport au bas
        right: 16, // Distance par rapport à la droite
      }}
      icon={<SpeedDialIcon />}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
    >
      {actions.map((action) => (
        <SpeedDialAction
          key={action.name}
          icon={action.icon}
          tooltipTitle={action.name}
          onClick={() => handleActionClick(action.action)}
        />
      ))}
    </SpeedDial>
  );
}
