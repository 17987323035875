import React, { useEffect } from "react";

import Simulator from "../simulator/Simulator";
import { Box, Container, Stack, Typography } from "@mui/material";
import Conditions from "../reservation/Conditions";
import DeviseList from "../devise/DeviseList";
import ServiceCard from "./ServiceCard";
import { Helmet } from "react-helmet-async";

function Services() {
  const services = [
    { titre: "Carte prépayée rechargeable ", 
      description: "Profitez de la flexibilité et de la commodité que nous vous offrons : choisissez de recevoir votre argent en espèces ou optez pour une carte prépayée rechargeable, que vous pouvez utiliser partout au Maroc. Cette carte est acceptée chez tous les commerçants, vous permettant d'effectuer vos achats en toute simplicité. De plus, vous pouvez retirer de l'argent à tout moment dans tous les guichets automatiques, vous garantissant ainsi un accès facile à vos fonds où que vous soyez. Réalisez des transactions en toute sécurité et gérez votre budget selon vos besoins", 
      image: 'card.jpg' 
    },

    { titre: "Coupures de devises ", 
      description: "Vous avez la liberté de commander les coupures de devises de votre choix, parfaitement adaptées à vos exigences personnelles ou professionnelles. Que vous ayez besoin de petites coupures pour vos déplacements quotidiens ou de montants plus importants pour des projets spécifiques, notre service flexible vous garantit un accès rapide et pratique aux devises dont vous avez besoin.", 
      image: 'coupuresdevises.jpg' 
    },
    { titre: "Disponibilité des devises ", 
      description: "Profitez dès maintenant, selon vos besoins, de la disponibilité de toutes les devises cotées par Bank Al-Maghrib sur notre plateforme", 
      image: 'disponibildevises.jpg' 
    },
    { titre: "Rachat ", 
      description: "Transformez vos dirhams restants en devises internationales de votre choix, sans aucune commission supplémentaire !  Profitez de ce service sans frais cachés et maximisez la valeur de votre argent !", 
      image: 'rachat.jpg' 
    },
    { titre: "Paiement par carte bancaire ", 
      description: "Pour faciliter vos transactions, nous sommes heureux de vous annoncer que nous acceptons les paiements par carte bancaire. Profitez d'une méthode de paiement simple, rapide et sécurisée qui vous permet de gérer vos échanges de devises sans tracas, notre service est conçu pour vous offrir une expérience fluide et sans stress. Dites adieu aux soucis de transport d'argent liquide et payez en toute confiance grâce à la fiabilité de votre carte bancaire. ", 
      image: 'paiementcarte.jpg' 
    },
    { titre: "Livraison à domicile ", 
      description: "Pour votre plus grand confort, nous offrons un service de livraison d'argent à domicile, vous permettant de recevoir vos devises directement chez vous, en toute sécurité. Après avoir complété les formalités prévues par la réglementation de change, notre équipe vous garantit une expérience fluide et rapide. Ce service innovant vous épargne les désagréments des déplacements, tout en répondant parfaitement à vos besoins en matière de liquidités. Avec notre service de livraison à domicile, nous nous engageons à vous offrir une tranquillité d'esprit inégalée (voir modalités et conditions d’utilisation) ", 
      image: 'livraisondomicile.jpg' 
    },
    
  ];
  useEffect(() => {
    // Remettre le défilement en haut de la page à chaque chargement
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100vw",
        pt: { xs: 4, sm: 8 },
        pb: { xs: 2, sm: 4 },
        pl: { xs: 4, sm: 8 },
        pr: { xs: 4, sm: 8 },
        mb: 4,
        background: "#ffdb02",
      }}
    >
      <Helmet>
        <title>Nos services - EXCHANGE.COM</title>
        <meta
          name="description"
          content="EXCHANGE.COM propose des services fiables de change de devises à Marrakech et au Maroc. Échangez facilement des dirhams marocains (MAD) avec nous. Visitez notre site pour découvrir nos taux compétitifs et effectuer des transactions en toute simplicité."
        />
      </Helmet>
      <Typography
        variant="h3"
        align="center"
        sx={{
          fontSize: { xs: 24, sm: 56 },
          color: "black",
          pb: { xs: 4, sm: 8 },
          animation: "slideDown 0.8s ease-out",
          "@keyframes slideDown": {
            "0%": {
              opacity: 0,
              transform: "translateY(-100px)",
            },
            "100%": {
              opacity: 1,
              transform: "translateY(0)",
            },
          },
        }}
      >
        Profitez de nos services et promotions
      </Typography>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, fontSize: { xs: 32, sm: 56 }, md: 4 }}
        useFlexGap
        flexWrap="wrap"
        justifyContent={"center"}
      >
        {services.map((service, index) => (
        <ServiceCard 
          key={index}
          titre={service.titre}
          description={service.description}
          image={service.image}
        />
      ))}
        
   

      </Stack>
    </Box>
  );
}

export default Services;
