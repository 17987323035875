"use client";
import { motion } from "framer-motion";
import React from "react";
import { ImagesSlider } from "../ui/images-slider";
import { useNavigate } from "react-router-dom";

export function ImagesSliderDemo() {
  const navigate = useNavigate();
  const images = [
    process.env.REACT_APP_URL_IMG + "/images/b1.jpg",
    process.env.REACT_APP_URL_IMG + "/images/b2.jpg",
    process.env.REACT_APP_URL_IMG + "/images/bureaux/menara.jpg",
    process.env.REACT_APP_URL_IMG + "/images/b3.jpg",

  ];
  return (
    <ImagesSlider className="h-[40rem]" images={images}>
      <motion.div
        initial={{
          opacity: 0,
          y: -80,
        }}
        animate={{
          opacity: 1,
          y: 0,
        }}
        transition={{
          duration: 0.6,
        }}
        className="z-50 flex flex-col justify-center items-center"
      >
        <motion.p className="font-bold text-xl md:text-6xl text-center bg-clip-text text-transparent bg-gradient-to-b from-yellow-400 to-gray-400 py-4">
    Savourez le charme de Marrakech <br /> Profitez de nos services
</motion.p>

        <button 
        onClick={() => navigate('/aboutus')}
        
        className="px-4 py-2 backdrop-blur-sm border bg-yellow-300/10 border-yellow-500/20 text-white mx-auto text-center rounded-full relative mt-4">
          <span>Découvrez nos Bureaux →</span>
          <div className="absolute inset-x-0  h-px -bottom-px bg-gradient-to-r w-3/4 mx-auto from-transparent via-emerald-500 to-transparent" />
        </button>
      </motion.div>
    </ImagesSlider>
  );
}
