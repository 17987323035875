import React from "react";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardMedia,
  Container,
  Stack,
  Typography,
} from "@mui/material";
import ForwardIcon from "@mui/icons-material/Forward";
import { deepOrange } from "@mui/material/colors";

const Steep = ({ number,image, title, description }) => {
  return (
    <Card
      sx={{
        display: "flex",
        minWidth: 280,
        minHeight: 150,
        mb: 2,
        transition: "transform 0.3s, opacity 0.3s", // Transition effect
        "&:hover": {
          transform: "scale(1.05)", // Slight zoom effect on hover
          opacity: 0.9,
        },
      }}
    >
      <CardMedia component="img" sx={{ width: 120 }} image={image} alt={title} />
      
      <Box sx={{ width: 600 }}>
      <Avatar
  sx={{
    background: "linear-gradient(45deg, yellow, orange)", // Dégradé jaune à orange
    color: "white", // Pour s'assurer que le texte est bien visible
    marginLeft: 'auto', // Aligner à droite
  }}
>
  {number}
</Avatar>
        <CardContent sx={{ flex: "1 0 auto" }}>
          <Typography component="div" paragraph>
            {description}
          </Typography>
        </CardContent>
      </Box>
    </Card>
  );
};

const Steeps = () => {
  const steepsData = [
    {
      number: "1",
      image: "/images/steeps/montant.jpg",
      title: "Indiquez le montant et la devise souhaités",
      description: "Indiquez le montant et la devise souhaités",
    },
    {
      number: "2",
      image: "/images/steeps/b4.jpg",
      title: "Indiquez le bureau de récupération",
      description: "Indiquez le bureau de récupération",
    },
    {
      number: "3",
      image: "/images/saisie.jpg",
      title: "Saisissez vos informations",
      description: "Saisissez vos informations",
    },
    {
      number: "4",
      image: "/images/steeps/fin.jpg",
      title: "Récupérez votre argent",
      description: "Récupérez votre argent",
    },
  ];

  return (
    <Container>
      <Stack
        direction={{ xs: "column", md: "row" }}
        spacing={2}
        sx={{
          pt: { xs: 2, sm: 4 },
          pb: { xs: 2, sm: 4 },
          justifyContent: "center",
        }}
      >
        {steepsData.map((step, index) => (
          <React.Fragment key={index}>
            <Steep
              number={step.number}
              image={step.image}
              title={step.title}
              description={step.description}
            />
            {index < steepsData.length - 1 && (
              <Box
                sx={{
                  display: { xs: "none", sm: "flex" },
                  flexDirection: "column",
                  justifyContent: "center",
                  transition: "transform 0.3s, opacity 0.3s", // Transition for icon
                  "&:hover": {
                    transform: "rotate(15deg)", // Rotate effect on hover
                    opacity: 0.7,
                  },
                }}
              >
                <ForwardIcon />
              </Box>
            )}
          </React.Fragment>
        ))}
      </Stack>
    </Container>
  );
};

export default Steeps;
