import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useGetAllDevisesQuery } from "../../app/services/deviseApi";
import Loader from "../loader/Loader";

function HistoriqueTable() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const { data: devises, isLoading, isError } = useGetAllDevisesQuery();
  const [editableDevises, setEditableDevises] = useState([]);
  useEffect(() => {
    if (devises) {
      // Préparer les devises pour être modifiables
      setEditableDevises(
        devises.map((devise) => ({
          ...devise,
          prixAchatTemp: devise.prixAchat,
          prixVenteTemp: devise.prixVente,
        }))
      );
    }
  }, [devises]);

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return (
      <Typography color="error">
        Erreur lors du chargement des devises.
      </Typography>
    );
  }

  return (
    <Container maxWidth="xl" sx={{ backgroundColor: "#ffff8d", pt: 0, pb: 2 }}>
      <Typography variant="h5">Historique des cours de devises</Typography>
      <Typography paragraph>
        Afficher l'historique des mises à jours des prix d'achat et de vente de
        devises.
      </Typography>
      <Box sx={{ backgroundColor: "white" }}>
        <TableContainer sx={{ mt: 3 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Image</TableCell>
                <TableCell>Nom</TableCell>
                <TableCell>Pays</TableCell>
                <TableCell>Prix d'achat (MAD)</TableCell>
                <TableCell>Prix de vente (MAD)</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {editableDevises.map((devise) => (
                <TableRow key={devise.id}>
                  <TableCell>
                  <img
    src={`${process.env.REACT_APP_BASE_FILE_URL}/Devise/image/${devise.image}`}
    alt={devise.name}
    style={{
      width: 40,
      height: 32,
      cursor: 'pointer',
      transition: 'transform 0.2s ease-in-out', // Animation fluide
    }}
    onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.2)')} // Agrandir au survol
    onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')} // Revenir à la taille normale
    onClick={() => navigate(`/admin/historiquedevise/${devise.id}`)}
  />
                  </TableCell>
                  <TableCell>{devise.name}</TableCell>
                  <TableCell>{devise.pays}</TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      size="small"
                      value={devise.prixAchatTemp}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      size="small"
                      value={devise.prixVenteTemp}
                    />
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        navigate(`/admin/historiquedevise/${devise.id}`)
                      }
                    >
                      Historique
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Container>
  );
}

export default HistoriqueTable;
