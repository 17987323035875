import React from "react";
import { Box, Container, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Steeps from "../steeps/Steeps";
import MediaControlCard from "./MediaControlCard";
import Steeps2 from "../steeps/Steeps2";
import { motion } from "framer-motion";

export default function SteepReservation() {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        background: "white",
        //display: 'flex',
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
      }}
      className="flex flex-col items-center justify-center min-h-[48rem]"
    >
      <motion.p className="font-bold text-xl md:text-6xl text-center bg-clip-text text-transparent bg-gradient-to-b from-yellow-500 to-yellow-300 py-4">
        Réservez gratuitement en ligne <br />
        en quatre étapes !
      </motion.p>

      <Typography
        variant="body1"
        sx={{
          color: "#212121",
          pt: 4,
          px: { xs: 2, md: 16 }, // Responsive padding for text
          fontSize: { xs: "0.9rem", md: "1rem" }, // Responsive font size
          textAlign: "justify", // Justify text for better readability
        }}
      >
        La réservation de vos devises n’a jamais été aussi simple ! Suivez ces
        quatre étapes faciles : indiquez le montant souhaité, choisissez votre
        bureau de récupération, entrez vos coordonnées et récupérez votre argent. En un rien de temps,
        vous aurez sécurisé votre réservation, vous permettant ainsi de voyager
        sereinement sans stress ni files d'attente.
      </Typography>

      <Container sx={{ display: "flex", justifyContent: "center" }}>
        <Steeps />
      </Container>
    </Box>
  );
}
