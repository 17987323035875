import * as React from 'react';
import  {useEffect} from 'react';
import { useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { BarPlot } from '@mui/x-charts/BarChart';
import { ResponsiveChartContainer } from '@mui/x-charts/ResponsiveChartContainer';
import { Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { ChartsXAxis } from '@mui/x-charts/ChartsXAxis';
import { ChartsYAxis } from '@mui/x-charts/ChartsYAxis';
import { ChartsTooltip } from '@mui/x-charts/ChartsTooltip';
import { ChartsAxisHighlight } from '@mui/x-charts/ChartsAxisHighlight';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import { useGetHistoriquedeviseQuery } from '../../app/services/historiquedeviseApi';
import Loader from '../loader/Loader';

export default function CoursChart() {
  const { id } = useParams(); 
  const {
    data: historiquedevises = [], error, isLoading,refetch
  } = useGetHistoriquedeviseQuery(id);
  useEffect(() => {
    refetch();
}, []);

  if (isLoading) return <Loader />;
  if (error) return <div>Error: {error.message}</div>;

  if (!historiquedevises.length) {
    return <div>Aucune donnée disponible pour l'historique des cours.</div>;
  }

  // Trier les données par date (created_at) dans l'ordre croissant
  const sortedHistoriquedevises = [...historiquedevises].sort(
    (a, b) => new Date(a.created_at) - new Date(b.created_at)
  );

  // Configuration des séries pour le graphique
  const series = [
    {
      type: 'bar',
      yAxisId: 'volume',
      label: 'Prix Achat',
      color: '#b26500',
      data: sortedHistoriquedevises.map((devise) => devise.prixAchat),
      highlightScope: { highlight: 'item' },
    },
    {
      type: 'bar',
      yAxisId: 'volume',
      label: 'Prix Vente',
      color: '#ff9100',
      data: sortedHistoriquedevises.map((devise) => devise.prixVente),
      highlightScope: { highlight: 'item' },
    },
  ];

  return (
    <Container maxWidth="xl" sx={{ backgroundColor: "#ffff8d", pt: 0, pb: 2 }}>
      <Typography variant="h5">
        Historique des mise à jour des cours
      </Typography>
      <img
        src={`${process.env.REACT_APP_BASE_FILE_URL}/Devise/image/${sortedHistoriquedevises[0]?.devise?.image}`}
        alt={sortedHistoriquedevises[0]?.devise?.image || 'Image indisponible'}
        style={{ width: 32, height: 24 }}
      />
      <Typography paragraph>{historiquedevises[0]?.name || 'Nom indisponible'}</Typography>

      <div style={{ width: '100%' }}>
        <Typography>Historique</Typography>
        <div>
          <ResponsiveChartContainer
            series={series}
            height={400}
            margin={{ top: 10 }}
            xAxis={[
              {
                id: 'date',
                data: sortedHistoriquedevises.map((devise) => devise.created_at),
                scaleType: 'band',
                showTicks: true,
              },
            ]}
            yAxis={[
              {
                id: 'price',
                scaleType: 'linear',
              },
              {
                id: 'volume',
                scaleType: 'linear',
                valueFormatter: (value) => `${(value / 1000000).toLocaleString()}M`,
              },
            ]}
          >
            <ChartsAxisHighlight x="line" />
            <BarPlot />
            <ChartsXAxis
              label="Date"
              position="bottom"
              axisId="date"
              tickLabelStyle={{ fontSize: 10 }}
              sx={{
                [`& .${axisClasses.label}`]: {
                  transform: 'translateY(5px)',
                },
              }}
            />
            <ChartsYAxis
              label="Price (MAD)"
              position="left"
              axisId="price"
              tickLabelStyle={{ fontSize: 10 }}
              sx={{
                [`& .${axisClasses.label}`]: {
                  transform: 'translateX(-5px)',
                },
              }}
            />
            <ChartsTooltip />
          </ResponsiveChartContainer>
        </div>
      </div>

      <Typography variant="h6" sx={{ mt: 4 }}>
        Historique détaillé
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Heure</TableCell>
              <TableCell align="right">Prix Achat</TableCell>
              <TableCell align="right">Prix Vente</TableCell>
              <TableCell align="right">Modifié par</TableCell>
              <TableCell align="right">Email</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {historiquedevises.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.date || 'Non défini'}</TableCell>
                <TableCell>{row.heure || 'Non défini'}</TableCell>
                <TableCell align="right">{parseFloat(row.prixAchat || 0).toFixed(4)}</TableCell>
                <TableCell align="right">{parseFloat(row.prixVente || 0).toFixed(4)}</TableCell>
                <TableCell align="right">{row.user?.name || 'Anonyme'}</TableCell>
                <TableCell align="right">{row.user?.email || 'Email non défini'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}
