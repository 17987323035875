"use client";

import { TypewriterEffect } from "../ui/typewriter-effect";
import { Button, Stack, Typography } from "@mui/material";
import { InscriptionComponent } from './InscriptionComponent';
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

export function Bienvenue() {
    const navigate = useNavigate();
    const { user } = useAuth();
  const words = [
    { text: "Bienvenue" },
     { text: user?.user?.name },
    // { text: "compte" },
    { text: "sur" },
    { text: "EXCHANGE.COM", className: "text-yellow-300 dark:text-yellow-300" },
  ];

  return (
    <div className="flex flex-col items-center justify-center h-[40rem]">
      
      <TypewriterEffect words={words} />
      <Typography
        variant="body1"
        color="textSecondary"
        className="mb-6 text-center"
      >
        Votre partenaire de confiance pour des
        opérations de change de devises rapides et sécurisés à Marrakech et partout au Maroc ! 
      </Typography>
      <Typography
        variant="body1"
        color="textSecondary"
        className="mb-6 text-center"
      >
        Explorez nos services et profitez de nos taux compétitifs.
      </Typography>
      <Stack
        direction={{ xs: "column", md: "row" }}
        spacing={2}
        justifyContent="center"
        className="mt-8"
      >
        <Button
          variant="contained"
          onClick={() => navigate('/reservation')}
          color="primary"
          className="w-40 h-10 rounded-lg"
        >
          Réservation
        </Button>
        <Button
          variant="outlined"
          onClick={() => navigate('/services')}
          color="primary"
          className="w-40 h-10 rounded-lg"
        >
          Nos Services
        </Button>
      </Stack>
    </div>
  );
}
