import React from 'react';
import { useAuth } from "../../contexts/AuthContext";
import { Bienvenue } from './Bienvenue';
import { InscriptionComponent } from './InscriptionComponent';
import { Box, Stack } from '@mui/material';
import Steeps2 from '../steeps/Steeps2';


function Inscription() {
    const { user } = useAuth();
  return (
    <div>
      {user?(
        <Bienvenue />
      ):(
        <InscriptionComponent />
      )}
    </div>
  )
}

export default Inscription
