import React, { useEffect, useState } from "react";
import axios from "../../axios";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { Link, useNavigate } from "react-router-dom";
import { Tab, Tabs, useMediaQuery, Stack, Divider } from "@mui/material";
//import { useState } from "react";
import Logo from "./Logo";
import { useAuth } from "../../contexts/AuthContext";

import HomeIcon from "@mui/icons-material/Home";
import GroupWorkSharpIcon from "@mui/icons-material/GroupWorkSharp";
import MiscellaneousServicesSharpIcon from "@mui/icons-material/MiscellaneousServicesSharp";
import CurrencyExchangeSharpIcon from "@mui/icons-material/CurrencyExchangeSharp";
import MailSharpIcon from "@mui/icons-material/MailSharp";
import FeedIcon from '@mui/icons-material/Feed';

const pages = [
  "ACCUEIL",
  "ACTUALITES",
  "QUI SOMMES NOUS ?",
  "SERVICES",
  "RESERVATION",
  "CONTACT",
];

const settings = ["Profile", "Account", "Dashboard", "Logout"];

function ResponsiveAppBar() {
  const [value, setValue] = useState(0);
  const links = [
    { route: "/", label: "ACCUEIL", icone: <HomeIcon /> },
    {
      route: "/aboutus",
      label: "QUI SOMMES NOUS ?",
      icone: <GroupWorkSharpIcon />,
    },
    {
      route: "/services",
      label: "SERVICES",
      icone: <MiscellaneousServicesSharpIcon />,
    },
    {
      route: "/reservation",
      label: "RESERVATION",
      icone: <CurrencyExchangeSharpIcon />,
    },
    {
      route: "/actualites",
      label: "ACTUALITES",
      icone: <FeedIcon />,
    },
    { route: "/contact", label: "CONTACT", icone: <MailSharpIcon /> },
  ];

  const { user } = useAuth();
  
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      const resp = await axios.post("/logout");

      if (resp.status === 200) {
        localStorage.removeItem("user");
        window.location.href = "/";
      }
    } catch (error) {
      console.log(error);
    }
  };
// deconnection if not remember me
  useEffect(() => {
    if (!localStorage.getItem("user")) {
      return () => {
        handleLogout();
      };
    }
  }, []);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (r) => {
    setAnchorElNav(null);
    if (typeof r === "string") {
      navigate(r);
    }
  };

  const handleCloseUserMenu = (r) => {
    setAnchorElUser(null);
    if (typeof r === "string") {
      navigate(r);
    }
  };

  return (
    <AppBar position="sticky" sx={{ backgroundColor: "black", pr: { xs: 4, sm: 8 }}}>
        <Toolbar sx={{width: "100vw"}}>
          <Logo />
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" }, pr: { xs: 4, sm: 8 } }}>

            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {links.map((link, index) => (
                <MenuItem
                  key={index}
                  onClick={() => handleCloseNavMenu(link.route)}
                >
                  <Typography textAlign="center">
                    <IconButton
                      size="large"
                      edge="start"
                      color="inherit"
                      aria-label="menu"
                    >
                      {link.icone}
                    </IconButton>
                    {link.label}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <img src="/images/logo2.png" alt="" height={40} />
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "center",
            }}
          >
            <Tabs textColor="inherit" value={value} indicatorColor="primary">
              {links.map((link, index) => (
                <Link
                  to={link.route}
                  key={index}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <Tab
                    label={
                      <Stack direction="row" spacing={1} alignItems="center">
                        <IconButton
                      size="small"
                      edge="start"
                      color="inherit"
                      aria-label="menu"
                    >
                        {/* {link.icone} */}
                        </IconButton>

                      {link.label}
                      </Stack>
                      }
                    onClick={(e, val) => setValue(index)}
                  />
                </Link>
              ))}
            </Tabs>
          </Box>
          {user ? (
            <Box sx={{ flexGrow: 0,pr:2 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="Remy Sharp" src="/images/bureaux/b2.jpg" />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <Box sx={{ p: 2 }}>
                  <Stack
                    direction={"row"}
                    spacing={2}
                    justifyContent={"space-around"}
                  >
                    <Stack direction={"column"} spacing={1}>
                      <Typography variant="h5">{user?.user?.name}</Typography>
                      <Typography variant="subtitle2">{user?.user?.email}</Typography>
                    </Stack>
                    <Avatar alt="Remy Sharp" src="/images/bureaux/b2.jpg" />
                  </Stack>
                  <Button variant="contained" onClick={handleLogout}>
                    Déconnexion
                  </Button>
                  <Divider />
                </Box>
                <MenuItem onClick={() => handleCloseUserMenu("/profile")}>
                  <Typography textAlign="center">Profile</Typography>
                </MenuItem>
                {user?.user?.role==='admin' ? <MenuItem onClick={() => handleCloseUserMenu("/admin")}>
                  <Typography textAlign="center">Tableau de bord</Typography>
                </MenuItem>:<></>}
                
              </Menu>
            </Box>
          ) : (
            <Stack direction="row" spacing={2}  sx={{ pr: { xs: 2, sm: 4 },fontSize: { xs: 12, sm: 16 }}}>
              <Link
                to="/login"
                style={{
                  textDecoration: "none",
                  color: "goldenrod",
            
                }}
              >
                Connexion
              </Link>
              <Link
                to="/register"
                style={{
                  textDecoration: "none",
                  color: "goldenrod",
                  // fontSize: { xs: 8, sm: 16 },
                }}
              >
                S'inscrir
              </Link>
            </Stack>
          )}
        </Toolbar>
    </AppBar>
  );
}
export default ResponsiveAppBar;
