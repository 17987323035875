"use client";

import { TypewriterEffect } from "../ui/typewriter-effect";
import { Button, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export function InscriptionComponent() {
  const navigate = useNavigate();
  const words = [
    { text: "Créer" },
    { text: "votre" },
    { text: "compte" },
    { text: "avec" },
    { text: "EXCHANGE.COM", className: "text-yellow-300 dark:text-yellow-300" },
  ];

  return (
    <div className="flex flex-col items-center justify-center h-[40rem]">
      <Typography
        variant="body1"
        color="textSecondary"
        className="mb-6 text-center"
      >
        Rejoignez-nous pour profiter de nos services exclusifs et simplifier vos opérations de change !
      </Typography>
      <TypewriterEffect words={words} />
      <Stack
        direction={{ xs: "column", md: "row" }}
        spacing={2}
        justifyContent="center"
        className="mt-8"
      >
        <Button
          variant="contained"
          onClick={() => navigate('/login')}
          color="primary"
          className="w-40 h-10 rounded-lg"
        >
          Se connecter
        </Button>
        <Button
          variant="outlined"
          onClick={() => navigate('/register')}
          color="primary"
          className="w-40 h-10 rounded-lg"
        >
          S'inscrire
        </Button>
      </Stack>
    </div>
  );
}
