import React,{ useEffect} from "react";
import {
  Box,
  Button,
  Container,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Spotlight } from "../ui/Spotlight.tsx";

const Home = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  useEffect(() => {
    // Remettre le défilement en haut de la page à chaque chargement
    window.scrollTo(0, 0);
}, []);
  return (
    <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          // pt: { xs: 2, sm: 4 },
          // pb: { xs: 2, sm: 4 },
          pl: { xs: 4, sm: 8 },
          pr: { xs: 4, sm: 8 },
          background:"#000000",
          
        }}
        >
      <Helmet>
        <title>
          EXCHANGE MAROC - Change de devises à Marrakech et au Maroc
        </title>
        <meta
          name="description"
          content="EXCHANGE.COM propose des services fiables de change de devises à Marrakech et au Maroc. Échangez facilement des dirhams marocains (MAD) avec nous. Visitez notre site pour découvrir nos taux compétitifs et effectuer des transactions en toute simplicité."
        />
      </Helmet>

      <div className="h-[40rem] w-full rounded-md flex md:items-center md:justify-center bg-black/[0.96] antialiased bg-grid-white/[0.02] relative overflow-hidden">
        <Spotlight
          className="-top-40 left-0 md:left-60 md:-top-20"
          fill="white"
        />
        <div className="p-4 max-w-7xl mx-auto relative z-10 w-full pt-20 md:pt-0">
          <h1 className="text-4xl md:text-7xl font-bold text-center bg-clip-text text-transparent bg-gradient-to-b from-yellow-400 to-yellow-200 bg-opacity-50">
            EXCHANGE.COM&nbsp; <br /> Le meilleur du change à Marrakech.
          </h1>

          <p className="mt-4 font-normal text-base text-neutral-300 max-w-lg text-center mx-auto">
            Prêt à commencer ? Planifiez votre voyage et réservez dès aujourd'hui votre devise avec EXCHANGE.COM et découvrez la différence que nous pouvons faire pour vous a traves nos six point de change à Marrakech !
          </p>
          
          {/* Envelopper les boutons dans un Stack pour les centrer */}
          <Stack direction="row" spacing={2} justifyContent="center" mt={4}>
            <Button 
              variant="contained" 
              color="primary" 
              onClick={() => navigate('/reservation')}
            >
              Réservez votre devise
            </Button>
            <Button 
              variant="outlined" 
              color="primary" 
              onClick={() => navigate('/services')}
            >
              Nos Services
            </Button>
          </Stack>
        </div>
      </div>
    </Box>
  );
};

export default Home;
